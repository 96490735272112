<template>
  <div class="card p-4">
    <div class="mb-4 h4 font-weight-bold text-color-green">
      Detail Spesimen Klinis
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card">
          <div class="card-body" style="background-color: #f6feff">
            <div class="">
              <div class="row">
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Nama
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Tanggal Lahir
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Usia
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Jenis Kelamin
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Cara Pembayaran
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.payment_method }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Dokter
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Doctor.User.name }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        No. RM
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Status
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.status }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 mb-4"
        v-for="(component, idx) in masterSpecimens"
        :key="idx"
      >
        <h5>{{ component.name }}</h5>
        <div class="row">
          <div class="col-6">
            <p>Status Spesimen</p>
            <a-select
              ref="select"
              class="w-100"
              v-model="component.status"
              @change="(value) => handleStatus(value, idx)"
            >
              <a-select-option value="" selected
                >Pilih Status Spesimen</a-select-option
              >
              <a-select-option
                v-for="(dataStatus, idx) in statusOptions"
                :key="idx"
                :value="dataStatus.code"
                >{{ dataStatus.display }}
              </a-select-option>
            </a-select>
            <div
              class="d-flex align-items-center mt-3"
              v-if="!component.isStatusExist"
            >
              <InfoCircleOutlined style="color: #dc3545" />
              <p class="m-0 ml-2" style="color: #dc3545">
                Status spesimen masih kosong.
              </p>
            </div>
          </div>
          <div class="col-6">
            <p>Asal Sumber</p>

            <a-button
              type="secondary"
              class="d-flex align-items-center justify-content-between w-100"
              @click="handleToggleModal('source', idx)"
            >
              <span>{{
                component.sourceOfOrigin
                  ? component.sourceOfOrigin.display
                  : "Pilih Asal Sumber"
              }}</span>
              <img
                src="resources/images/search-icon.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
            </a-button>
            <div
              class="d-flex align-items-center mt-3"
              v-if="!component.isSocExist"
            >
              <InfoCircleOutlined style="color: #dc3545" />
              <p class="m-0 ml-2" style="color: #dc3545">
                Asal Sumber masih kosong.
              </p>
            </div>
          </div>
          <div class="col-6 mt-4">
            <p>Lokasi Pengambilan</p>
            <a-button
              type="secondary"
              class="d-flex align-items-center justify-content-between w-100"
              @click="handleToggleModal('location', idx)"
            >
              <span>{{
                component.location
                  ? component.location.display
                  : "Pilih Lokasi Pengambilan"
              }}</span>
              <img
                src="resources/images/search-icon.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
            </a-button>
            <div
              class="d-flex align-items-center mt-3"
              v-if="!component.isLocationExist"
            >
              <InfoCircleOutlined style="color: #dc3545" />
              <p class="m-0 ml-2" style="color: #dc3545">
                Lokasi pengambilan masih kosong.
              </p>
            </div>
          </div>
          <div class="col-6 mt-4">
            <p>Jumlah dan Volume</p>
            <div class="row">
              <div class="col-4">
                <a-input type="number" v-model="component.value"></a-input>
                <div
                  class="d-flex align-items-center mt-3"
                  v-if="!component.isJumlahExist"
                >
                  <InfoCircleOutlined style="color: #dc3545" />
                  <p class="m-0 ml-2" style="color: #dc3545">
                    Jumlah masih kosong.
                  </p>
                </div>
              </div>
              <div class="col-8">
                <a-button
                  type="secondary"
                  class="d-flex align-items-center justify-content-between w-100"
                  @click="handleToggleModal('unit', idx)"
                >
                  <span>{{
                    component.unit
                      ? `${component.unit.code} (${component.unit.unit})`
                      : "Pilih Satuan Ukur"
                  }}</span>
                  <img
                    src="resources/images/search-icon.svg"
                    alt="Icon"
                    style="height: 16px; width: 16px"
                  />
                </a-button>
                <div
                  class="d-flex align-items-center mt-3"
                  v-if="!component.isVolumeExist"
                >
                  <InfoCircleOutlined style="color: #dc3545" />
                  <p class="m-0 ml-2" style="color: #dc3545">
                    Volume masih kosong.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mt-4">
            <p>Waktu Pengambilan</p>
            <div class="row">
              <div class="col-6">
                <a-date-picker
                  v-model="component.date"
                  placeholder="YYYY-MM-DD"
                  class="w-100"
                ></a-date-picker>
              </div>
              <div class="col-6">
                <a-time-picker
                  v-model="component.time"
                  class="w-100"
                  placeholder="Pilih Jam"
                />
              </div>
            </div>
          </div>
          <div class="col-6 mt-4">
            <p>Metode Pengambilan</p>
            <a-button
              type="secondary"
              class="d-flex align-items-center justify-content-between w-100"
              @click="handleToggleModal('method', idx)"
            >
              <span>{{
                component.method
                  ? component.method.display
                  : "Pilih Metode Pengambilan"
              }}</span>
              <img
                src="resources/images/search-icon.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
            </a-button>
            <div
              class="d-flex align-items-center mt-3"
              v-if="!component.isMethodExist"
            >
              <InfoCircleOutlined style="color: #dc3545" />
              <p class="m-0 ml-2" style="color: #dc3545">
                Metode pengambilan masih kosong.
              </p>
            </div>
          </div>
          <div class="col-6 my-4">
            <p>Kondisi Pada Saat Pengambilan</p>
            <a-button
              type="secondary"
              class="d-flex align-items-center justify-content-between w-100"
              @click="handleToggleModal('condition', idx)"
            >
              <span>{{
                component.condition
                  ? component.condition.display
                  : "Pilih Kondisi Pengambilan"
              }}</span>
              <img
                src="resources/images/search-icon.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
            </a-button>
            <div
              class="d-flex align-items-center mt-3"
              v-if="!component.isConditionExist"
            >
              <InfoCircleOutlined style="color: #dc3545" />
              <p class="m-0 ml-2" style="color: #dc3545">
                Kondisi masih kosong.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <a-button
        type="primary"
        class="mt-2 d-flex flex-row align-items-center justify-content-center"
        style="width: 162px; border-radius: 26px"
        @click="submitData"
      >
        <img
          src="resources/images/save.png"
          alt="Icon"
          style="height: 18px; width: 18px"
        />
        <span style="margin-left: 5px">Simpan Spesimen</span></a-button
      >
      <a-button
        type="secondary"
        class="mt-2 d-flex flex-row align-items-center justify-content-center ml-3"
        style="width: 230px; border-radius: 26px"
      >
        <img
          src="resources/images/print.svg"
          alt="Icon"
          style="height: 18px; width: 18px"
        />
        <span style="margin-left: 5px">Cetak Label Spesimen</span></a-button
      >
    </div>
    <a-modal
      v-model="toggleSog"
      :title="
        columnType === 'source'
          ? 'Pilih Asal Sumber'
          : columnType === 'location'
          ? 'Pilih Lokasi Pengambilan'
          : columnType === 'method'
          ? 'Pilih Metode Pengambilan'
          : columnType === 'condition'
          ? 'Pilih Kondisi Pengambilan'
          : columnType === 'unit'
          ? 'Pilih Satuan Ukur'
          : 'Pilih Data'
      "
      :footer="null"
      centered
    >
      <a-input-search
        placeholder="Ketik nama pemeriksaan untuk mencari..."
        style="width: 350px"
        @change="searchSourceOfOrigin"
        class="mb-4"
      />
      <a-table
        :dataSource="computedDataTable"
        :columns="computedColumns"
        :rowKey="(record, index) => record.code"
        @rowClick="handleRowDataClick"
      ></a-table>
    </a-modal>
  </div>
</template>

<script>
import { getPatientVisit } from "@/services/axios/api/kunjungan"
import {
  getSpecimentSourceData,
  getExaminationRequestById,
  createExaminationSpecimen,
} from "@/services/axios/api/pemeriksaan"
// import { WarningOutlined } from '@ant-design/icons-vue'
import { notification } from "ant-design-vue"
import { getUserProfileFromId } from "@/services/axios/api/auth"
import { InfoCircleOutlined } from "@ant-design/icons-vue"

import moment from "moment"
export default {
  components: { InfoCircleOutlined },
  data() {
    return {
      activeKey: "1",
      name: "",
      date: null,
      filterText: "", // State for the filter input,
      sourceOfOrigin: [],
      filteredSourceOfOrigin: [],
      locationPickUp: [],
      filteredlocationPickUp: [],
      methodPickUp: [],
      filteredMethodPickUp: [],
      conditionPickUp: [],
      filteredConditionPickUp: [],
      unitValue: [],
      filteredUnitValue: [],
      activeIndex: 0,
      // INPUT DATA
      specimens: [
        {
          idExaminationComponent: "",
          status: "",
          sourceOfOrigin: "",
          location: "",
          value: "",
          unit: "",
          date: "",
          time: "",
          method: "",
          condition: "",
        },
      ],
      masterSpecimens: [],
      statusSpesimen: "",
      selectedLocation: "",
      selectedSourceOfOrigin: "",
      selectedMethod: "",
      selectedCondition: "",
      selectedUnit: "",
      valueUnit: "0",
      pickUpDate: "",
      pickUpTime: "",
      toggleSog: false,
      columnType: "",
      visit_id: "",
      sogColumns: [
        {
          title: "Code",
          dataIndex: "code",
          key: "code",
          width: "200px",
        },
        {
          title: "Display",
          dataIndex: "display",
          key: "display",
        },
      ],
      conditionColumns: [
        {
          title: "Code",
          dataIndex: "code",
          key: "code",
        },
        {
          title: "Display",
          dataIndex: "display",
          key: "display",
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
        },
      ],
      unitColumns: [
        {
          title: "Code",
          dataIndex: "code",
          key: "code",
        },

        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
        },
      ],
      statusOptions: [
        {
          code: "available",
          display: "Spesimen tersedia dan dalam kondisi baik",
        },
        {
          code: "unavailable",
          display: "Spesimen tidak tersedia karena hilang atau rusak",
        },
        {
          code: "unsatisfactory",
          display:
            "Spesimen tidak dapat digunakan karena isu kualitas seperti wadah yang rusak, kontaminasi, atau terlalu lama",
        },
        {
          code: "entered-in-error",
          display: "Data Spesimen yang dimasukkan error sehingga tidak valid",
        },
      ],
      patientVisitData: [],
    }
  },
  created() {
    this.visit_id = this.$route.params.id
    this.getName()
    this.fetchData()
  },
  methods: {
    calculateAge(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" }
      const date = new Date(dateString)
      return date.toLocaleString("id-ID", options)
    },
    formatDateTime(dateString) {
      const date = new Date(dateString)
      const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" }
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }

      const formattedDate = date
        .toLocaleDateString("id-ID", dateOptions)
        .split("/")
        .join("-")
      const formattedTime = date
        .toLocaleTimeString("id-ID", timeOptions)
        .split(".")
        .join(":")

      return `${formattedDate} ${formattedTime}`
    },
    handleStatus(value, idx) {
      console.log(value, idx)
    },
    getName() {
      const name = localStorage.getItem("name")
      this.name = name
    },
    async fetchData() {
      try {
        console.log(this.$route.params.id)
        const { data } = await getExaminationRequestById(this.$route.params.id)
        console.log(data, "inidatanyaa")
        await this.getPatientVisitData(data.data.idVisit)
        this.masterSpecimens = data.data.ExaminationRequestDetails
        this.masterSpecimens = this.masterSpecimens.map((e) => {
          const now = moment()

          return {
            ...e,
            idExaminationRequestDetail: e.id,
            idExaminationMaster: e.MasterExamination.id,
            name: e.MasterExamination.name,
            status: "available",
            sourceOfOrigin: "",
            location: "",
            value: 0,
            unit: "",
            time: now.toISOString(),
            method: "",
            condition: "",
            date: now.toISOString(),
            isStatusExist: true,
            isSocExist: true,
            isLocationExist: true,
            isJumlahExist: true,
            isVolumeExist: true,
            isMethodExist: true,
            isConditionExist: true,
          }
        })

        const { data: dataSourceOfOrigin } = await getSpecimentSourceData()
        // console.log(dataSourceOfOrigin)
        // Asal Sumber
        this.sourceOfOrigin = dataSourceOfOrigin.data.sourceOfOrigin
        this.filteredSourceOfOrigin = dataSourceOfOrigin.data.sourceOfOrigin
        // Lokasi Pengambilan
        this.locationPickUp = dataSourceOfOrigin.data.pickUpLocation
        this.filteredlocationPickUp = dataSourceOfOrigin.data.pickUpLocation
        // Metode Pengambilan
        this.methodPickUp = dataSourceOfOrigin.data.pickUpMethod
        this.filteredMethodPickUp = dataSourceOfOrigin.data.pickUpMethod
        // Kondisi Pengambilan
        this.conditionPickUp = dataSourceOfOrigin.data.pickUpCondition
        this.filteredConditionPickUp = dataSourceOfOrigin.data.pickUpCondition
        // Satuan Ukuran
        this.unitValue = dataSourceOfOrigin.data.unitValue
        this.filteredUnitValue = dataSourceOfOrigin.data.unitValue
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    },
    async getUserName(id) {
      try {
        const response = await getUserProfileFromId(id)
        return response.data.user.name
      } catch (error) {
        console.error("Error fetching user name:", error)
        return "Unknown"
      }
    },

    async getPatientVisitData(id) {
      const response = await getPatientVisit(id)
      console.log(response, "cobacekiniaja")
      this.patientVisitData = response.data.visit

      const perawatName = await this.getUserName(
        this.patientVisitData.Anamnesis.created_by,
      )

      this.patientVisitData = {
        ...this.patientVisitData,
        age: this.calculateAge(this.patientVisitData.Patient.birth_date),
        birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
        gender:
          this.patientVisitData.Patient.gender === "Male"
            ? "Laki-laki"
            : "Perempuan",
        payment_method:
          this.patientVisitData.Patient.payment_method === "JKN"
            ? "JKN (BPJS Kesehatan)"
            : this.patientVisitData.Patient.payment_method,
        anamnesis_created_time: this.formatDateTime(
          this.patientVisitData.Anamnesis.createdAt,
        ),
        anamnesis_created_by: perawatName,
      }

      // console.log(this.patientVisitData, 'data kunjungan pasien')
      // console.log(this.patientVisitDocument, 'dokumen kunjungan pasien')
    },

    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase()
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeStatus(selectedStatus) {
      this.filteredTableData =
        selectedStatus !== "Semua"
          ? this.tableData.filter((item) => item.status === selectedStatus)
          : [...this.filteredTableData]
    },
    handleDateChange(date) {
      if (!date) return
      const newDate = date.toISOString().split("T")[0]
      this.dateQuery = newDate
      // console.log(this.dateQuery, "tanggal");
      // this.getVisit()
    },
    handleAddExamination() {
      this.$router.push("/laborant/add")
    },
    goToDetail(record) {
      console.log(record, "<<<")
      this.$router.push(`/laborant/detail/${record.id}`)
    },

    getStatusColor(status) {
      const statusColorMap = {
        "Menunggu Perawat": "#875BF7",
        "Menunggu Spesimen": "#EF6820",
        "Diperiksa Dokter": "#2E90FA",
        "Menunggu Pemeriksaan": "#2E90FA",
        "Selesai Diperiksa": "#16B364",
      }
      return statusColorMap[status]
    },
    getStatusBackgroundColor(status) {
      const statusColorMap = {
        "Menunggu Perawat": "#F3F1FF",
        "Menunggu Spesimen": "#FFF2E4",
        "Diperiksa Dokter": "#E1F1FF",
        "Menunggu Pemeriksaan": "#E1F1FF",
        "Selesai Diperiksa": "#E1FBEA",
      }
      return statusColorMap[status]
    },
    handleToggleModal(type, idx) {
      this.activeIndex = idx
      this.columnType = type
      console.log(this.columnType)
      this.toggleSog = !this.toggleSog
    },
    handleRowDataClick(record) {
      if (this.columnType === "source") {
        this.masterSpecimens[this.activeIndex].sourceOfOrigin = record
        console.log(record, "iniudahdiselect")
        this.toggleSog = !this.toggleSog
      } else if (this.columnType === "location") {
        this.masterSpecimens[this.activeIndex].location = record
        console.log(this.selectedLocation, "iniudahdiselect")
        this.toggleSog = !this.toggleSog
      } else if (this.columnType === "method") {
        this.masterSpecimens[this.activeIndex].method = record
        console.log(this.selectedMethod, "iniudahdiselect")
        this.toggleSog = !this.toggleSog
      } else if (this.columnType === "condition") {
        this.masterSpecimens[this.activeIndex].condition = record
        console.log(this.selectedCondition, "iniudahdiselect")
        this.toggleSog = !this.toggleSog
      } else if (this.columnType === "unit") {
        console.log(record)
        this.masterSpecimens[this.activeIndex].unit = record
        this.toggleSog = !this.toggleSog
      }
    },

    searchSourceOfOrigin(e) {
      const filterValue = e.target.value.toLowerCase()
      if (this.columnType === "source") {
        this.filteredSourceOfOrigin = this.sourceOfOrigin.filter((item) =>
          item.display.toLowerCase().includes(filterValue),
        )
      } else if (this.columnType === "location") {
        this.filteredlocationPickUp = this.locationPickUp.filter((item) =>
          item.display.toLowerCase().includes(filterValue),
        )
      } else if (this.columnType === "method") {
        this.filteredMethodPickUp = this.methodPickUp.filter((item) =>
          item.display.toLowerCase().includes(filterValue),
        )
      } else if (this.columnType === "condition") {
        this.filteredConditionPickUp = this.conditionPickUp.filter((item) =>
          item.display.toLowerCase().includes(filterValue),
        )
      } else if (this.columnType === "unit") {
        this.filteredUnitValue = this.unitValue.filter((item) =>
          item.unit.toLowerCase().includes(filterValue),
        )
      }
    },
    async submitData() {
      // Validasi untuk memastikan tidak ada field kosong

      let isQualified = true
      // Lakukan format data jika validasi sudah lolos
      this.masterSpecimens = this.masterSpecimens.map((e) => {
        const formattedDate = e.date
          ? moment(e.date).format("YYYY-MM-DD") // Konversi e.date ke Moment
          : ""
        const formattedTime = e.time
          ? moment(e.time).format("HH:mm:ss") // Konversi e.time ke Moment
          : ""

        const combinedDateTime =
          formattedDate && formattedTime
            ? `${formattedDate} ${formattedTime}`
            : ""

        const statusMaster = this.statusOptions.filter(
          (el) => el.code === e.status,
        )

        // Perhitungan `isQualified`
        const isStatusExist = !!e.status
        const isSocExist = !!e.sourceOfOrigin
        const isLocationExist = !!e.location
        const isJumlahExist = !!e.value
        const isVolumeExist = !!e.unit
        const isMethodExist = !!e.method
        const isConditionExist = !!e.condition

        isQualified =
          isStatusExist &&
          isSocExist &&
          isLocationExist &&
          isJumlahExist &&
          isVolumeExist &&
          isMethodExist &&
          isConditionExist

        return {
          ...e,
          date: combinedDateTime,
          status: isQualified ? statusMaster[0] : e.status,
          isStatusExist,
          isSocExist,
          isLocationExist,
          isJumlahExist,
          isVolumeExist,
          isMethodExist,
          isConditionExist,
        }
      })

      console.log(this.masterSpecimens, "cekinibrother")

      if (isQualified) {
        const res = await createExaminationSpecimen(
          this.$route.params.id,
          this.masterSpecimens,
        )

        if (res.data.type === "success") {
          notification.success({
            message: res.data.message,
            description: "",
            duration: 5,
          })

          this.$router.push("/laborant/klinik")
        }
        // Kirim data ke API jika semua validasi terpenuhi
      }
    },
  },
  computed: {
    computedColumns() {
      if (
        this.columnType === "source" ||
        this.columnType === "location" ||
        this.columnType === "method"
      ) {
        return this.sogColumns
      } else if (this.columnType === "unit") {
        return this.unitColumns
      } else {
        return this.conditionColumns
      }
    },
    computedDataTable() {
      if (this.columnType === "source") {
        return this.filteredSourceOfOrigin
      } else if (this.columnType === "location") {
        return this.filteredlocationPickUp
      } else if (this.columnType === "method") {
        return this.filteredMethodPickUp
      } else if (this.columnType === "unit") {
        return this.filteredUnitValue
      } else {
        return this.filteredConditionPickUp
      }
    },
  },
}
</script>

<style scoped>
.select-custom .ant-select-selector {
  padding-left: 12px !important;
  /* Sesuaikan padding sesuai kebutuhan */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.delete-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.delete-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.delete-button:hover .icon-default {
  display: none;
}

.delete-button:hover .icon-hover {
  display: inline;
}

/* Default icon terlihat */
.edit-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.edit-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.edit-button:hover .icon-default {
  display: none;
}

.edit-button:hover .icon-hover {
  display: inline;
}
</style>
